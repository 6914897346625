<template>
    <div>
        <!-- Select Actions and Items Per Page Options -->
        <b-row>
            <b-col sm="6" md="6">
                <span class="details-view-title">CHANGE LOGS</span>
                <div class="details-view-subtitle">Recent update logs created by {{ isSuperAdmin ? 'all users' :
                    loggedUser.id }}</div>
            </b-col>
            <b-col sm="6" md="4" offset-md="2" class="mb-2 text-md-right">
                <b-input-group prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table show-empty striped hover :items="changeLogs" :fields="fields" :current-page="currentPage"
            :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" responsive>
            <template v-slot:cell(dateUpdated)="row">
                <div>
                    {{ getFormattedDateWithTime(row.item.new.dateUpdated) }}
                </div>
            </template>
            <template v-slot:cell(changes)="row">
                <!-- Primary Information -->
                <div class="changed-section" v-if="hasChangesInField(row.item, 'name')">
                    <b>Name</b>
                    <br>
                    From <b class="old-value"> {{ getOldValue(row.item, 'name') }} </b>
                    to <b class="new-value"> {{ getNewValue(row.item, 'name') }} </b>
                </div>
                <div class="changed-section" v-if="hasChangesInField(row.item, 'description')">
                    <b>Description</b>
                    <br>
                    From <b class="old-value"> {{ getOldValue(row.item, 'description') }} </b>
                    to <b class="new-value"> {{ getNewValue(row.item, 'description') }} </b>
                </div>

                <!-- Inventory Details -->
                <div class="changed-section mb-2" v-if="hasChangesInArrayItems(row.item, 'inventoryDetails')">
                    <b>Inventory Details</b>
                    <div v-show="getAddedArrayItems(row.item, 'inventoryDetails', 'fieldName').length > 0">
                        Added <b><span class="new-value">{{ getAddedArrayItems(row.item, 'inventoryDetails', 'fieldName') }}</span></b>
                    </div>
                    <div v-show="getRemovedArrayItems(row.item, 'inventoryDetails', 'fieldName').length > 0">
                        Removed <b><span class="old-value">{{ getRemovedArrayItems(row.item, 'inventoryDetails', 'fieldName') }}</span></b>
                    </div>
                </div>
                <div class="changed-section mb-2" v-if="hasChangesInArrayItemFields(row.item, 'inventoryDetails', 'isActive')">
                    <b>Inventory Details - Status</b>
                    <div class="mt-2" v-for="(changes, index) in getChangesInArrayItemFields(row.item, 'inventoryDetails', 'isActive')" :key="index">
                        <b>{{ changes.id.toUpperCase() }}</b>
                        <br>
                        From 
                        <span>
                            <b-badge v-if="changes.old === true" variant="success">Active</b-badge>
                            <b-badge v-else-if="changes.old === false" variant="secondary">Inactive</b-badge>
                            <b v-else class="old-value"> - </b>
                        </span>
                        to
                        <span>
                            <b-badge v-if="changes.new" variant="success">Active</b-badge>
                            <b-badge v-else variant="secondary">Inactive</b-badge>
                        </span>
                    </div>
                    <br>
                </div>
                <div class="changed-section mb-2" v-if="hasChangesInArrayItemFields(row.item, 'inventoryDetails', 'isRequired')">
                    <b>Inventory Details - Is Required?</b>
                    <div class="mt-2" v-for="(changes, index) in getChangesInArrayItemFields(row.item, 'inventoryDetails', 'isRequired')" :key="index">
                        <b>{{ changes.id.toUpperCase() }}</b>
                        <br>
                        From 
                        <span>
                            <b v-if="changes.old === true" class="old-value"> YES </b>
                            <b v-else-if="changes.old === false" class="old-value"> NO </b>
                            <b v-else class="old-value"> - </b>
                        </span>
                        to <b class="new-value"> {{ changes.new ? 'YES' : 'NO' }} </b>
                    </div>
                    <br>
                </div>

                <!-- Conditions -->
                <div class="changed-section mb-2" v-if="hasChangesInArrayItems(row.item, 'conditions')">
                    <b>Condition</b>
                    <div v-show="getAddedArrayItems(row.item, 'conditions', 'condition').length > 0">
                        Added <b><span class="new-value">{{ getAddedArrayItems(row.item, 'conditions', 'condition') }}</span></b>
                    </div>
                    <div v-show="getRemovedArrayItems(row.item, 'conditions', 'condition').length > 0">
                        Removed <b><span class="old-value">{{ getRemovedArrayItems(row.item, 'conditions', 'condition') }}</span></b>
                    </div>
                </div>
                <div class="changed-section mb-2" v-if="hasChangesInArrayItemFields(row.item, 'conditions', 'isActive')">
                    <b>Condition - Status</b>
                    <div class="mt-2" v-for="(changes, index) in getChangesInArrayItemFields(row.item, 'conditions', 'isActive')" :key="index">
                        <b>{{ changes.id.toUpperCase() }}</b>
                        <br>
                        From 
                        <span>
                            <b-badge v-if="changes.old === true" variant="success">Active</b-badge>
                            <b-badge v-else-if="changes.old === false" variant="secondary">Inactive</b-badge>
                            <b v-else class="old-value"> - </b>
                        </span>
                        to
                        <span>
                            <b-badge v-if="changes.new" variant="success">Active</b-badge>
                            <b-badge v-else variant="secondary">Inactive</b-badge>
                        </span>
                    </div>
                    <br>
                </div>
                <div class="changed-section mb-2" v-if="hasChangesInArrayItemFields(row.item, 'conditions', 'isBillable')">
                    <b>Condition - Is Billable?</b>
                    <div class="mt-2" v-for="(changes, index) in getChangesInArrayItemFields(row.item, 'conditions', 'isBillable')" :key="index">
                        <b>{{ changes.id.toUpperCase() }}</b>
                        <br>
                        From 
                        <span>
                            <b v-if="changes.old === true" class="old-value"> YES </b>
                            <b v-else-if="changes.old === false" class="old-value"> NO </b>
                            <b v-else class="old-value"> - </b>
                        </span>
                        to <b class="new-value"> {{ changes.new ? 'YES' : 'NO' }} </b>
                    </div>
                    <br>
                </div>

                <!-- Rental Tab -->
                <div class="changed-section" v-if="hasChangesInField(row.item, 'isRental')" v-show="isBillingModuleEnabled">
                    <b>Is Rental?</b>
                    <br>
                    From <b class="old-value"> {{ getOldValue(row.item, 'isRental') ? 'YES' : 'NO' }} </b>
                    to <b class="new-value"> {{ getNewValue(row.item, 'isRental') ? 'YES' : 'NO' }} </b>
                </div>
                <div class="changed-section" v-if="hasChangesInField(row.item, 'rentFrequency')" v-show="isBillingModuleEnabled && row.item.isRental">
                    <b>Rent Frequency</b>
                    <br>
                    From <b class="old-value"> {{ getOldValue(row.item, 'rentFrequency') }} </b>
                    to <b class="new-value"> {{ getNewValue(row.item, 'rentFrequency') }} </b>
                </div>
                <div class="changed-section" v-if="hasChangesInField(row.item, 'rentRate')" v-show="isBillingModuleEnabled && row.item.isRental">
                    <b>Rent Rate</b>
                    <br>
                    From <b class="old-value"> {{ getOldValue(row.item, 'rentRate') }} </b>
                    to <b class="new-value"> {{ getNewValue(row.item, 'rentRate') }} </b>
                </div>

                <!-- Subscription -->
                <div class="changed-section" v-if="hasChangesInField(row.item, 'isActive')">
                    <b>Status</b>
                    <br>
                    From
                    <AssetTypeStatus v-if="row.item.old !== null" :assetType="row.item.old" />
                    <b v-else class="old-value"> - </b>
                    to
                    <AssetTypeStatus :assetType="row.item.new" />
                </div>
                <div class="changed-section" v-if="hasChangesInField(row.item, 'isPublic')">
                    <b>Is Public?</b>
                    <br>
                    From <b class="old-value"> {{ getOldValue(row.item, 'isPublic') ? 'YES' : 'NO' }} </b>
                    to <b class="new-value"> {{ getNewValue(row.item, 'isPublic') ? 'YES' : 'NO' }} </b>
                </div>
                
            </template>
            <template v-slot:cell(updatedBy)="row">{{ row.item.new.updatedBy }}</template>
        </b-table>
        <b-row>
            <b-col md="8" sm="12" class="my-1">
                <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
            </b-col>
            <b-col md="4" sm="12" class="my-1">
                <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                    class="my-0" />
            </b-col>
        </b-row>

        <b-row class="mt-4 mb-2">
            <b-col sm="12" class="text-sm-right">
                <b-button size="sm" @click="row.toggleDetails" variant="secondary" v-b-tooltip.hover.top="'Hide Details'">
                    <i class="icon-arrow-up"></i>
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Utils
import { DateUtil } from '@/utils/dateutil';
import { BillingReportUtil } from '@/utils/billingReportUtil';

// Components
import AssetTypeStatus from '@/views/setup/assetType/AssetTypeStatus';

// DAO
import auditTrailLogsDAO from '@/database/auditTrailLogs';

// Others
import _ from 'lodash';


export default {
    name: 'asset-type-change-logs-details-view',
    components: {
        AssetTypeStatus
    },
    props: {
        row: {
            type: Object,
            required: true,
        },
        allCompaniesObj: {
			type: Object,
			required: true,
		},
    },
    data() {
        return {
            changeLogs: [],
            fields: [
                'dateUpdated',
                'changes',
                'updatedBy',
            ],
            currentPage: 1,
            perPage: 5,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,

            assetTypeId: '',

            isBillingModuleEnabled: false,
            loggedUser: this.$store.getters.loggedUser,
            isSuperAdmin: this.$store.getters.isSuperAdmin,
        };
    },
    watch: {
        assetTypeId: async function (newVal) {
            if (newVal && newVal.length > 0) {
                await this.retrieveChangeLog(newVal);
            }
        }
    },
    computed: {
		companyObj() {
			let companyId = this.row.item.originId;
			return this.allCompaniesObj[companyId] ? this.allCompaniesObj[companyId] : {};
		}
	},
    async mounted() {
        let permissions = this.companyObj.permissions ? this.companyObj.permissions : {};
		this.isBillingModuleEnabled = permissions.billing ? permissions.billing : false;

        this.assetTypeId = this.row.item.id;
        await this.retrieveChangeLog(this.assetTypeId);
    },
    methods: {
        async retrieveChangeLog(assetTypeId) {
            if (assetTypeId) {
                let param = {
                    collection: 'assetTypes',
                    id: assetTypeId,
                    userId: !this.isSuperAdmin ? this.loggedUser.id : ''
                }

                let results = await auditTrailLogsDAO.getAuditTrailLogs(param);
                this.changeLogs = Object.values(results);

                this.filterChangeLogs(this.changeLogs);
                this.totalRows = _.size(this.changeLogs);
            }
        },

        filterChangeLogs(changeLogs) {
            const filteredLogs = _.filter(changeLogs, (log) => {
                const oldLog = log.old ? log.old : {};
                const newLog = log.new ? log.new : {};

                // Name
                const oldName = oldLog.name ? oldLog.name : '';
                const newName = newLog.name ? newLog.name : '';
                // Description
                const oldDesc = oldLog.description ? oldLog.description : '';
                const newDesc = newLog.description ? newLog.description : '';
                // IsRental
                const oldIsRental = oldLog.isRental ? oldLog.isRental : false;
                const newIsRental = newLog.isRental ? newLog.isRental : false;
                // Rent Frequency
                const oldRentFrequency = oldLog.rentFrequency ? oldLog.rentFrequency : '';
                const newRentFrequency = newLog.rentFrequency ? newLog.rentFrequency : '';
                // Rent Rate
                const oldRentRate = oldLog.rentRate ? oldLog.rentRate : '';
                const newRentRate = newLog.rentRate ? newLog.rentRate : '';
                // Inventory Details
                const addedInventoryDetails = this.getAddedItems(oldLog.inventoryDetails, newLog.inventoryDetails).length;
                const removedInventoryDetails = this.getRemovedItems(oldLog.inventoryDetails, newLog.inventoryDetails).length;
                // Conditions
                const addedCondition = this.getAddedItems(oldLog.conditions, newLog.conditions).length;
                const removedCondition = this.getRemovedItems(oldLog.conditions, newLog.conditions).length;
                // Status
                const oldStatus = oldLog.isActive ? oldLog.isActive : false;
                const newStatus = newLog.isActive ? newLog.isActive : false;
                // IsPublic
                const oldIsPublic = oldLog.isPublic ? oldLog.isPublic : false;
                const newIsPublic = newLog.isPublic ? newLog.isPublic : false;

                return oldName !== newName || 
                    oldDesc !== newDesc ||
                    oldIsRental !== newIsRental ||
                    oldRentFrequency !== newRentFrequency ||
                    oldRentRate !== newRentRate ||
                    addedInventoryDetails > 0 ||
                    removedInventoryDetails > 0 ||
                    addedCondition > 0 ||
                    removedCondition > 0 ||
                    oldStatus !== newStatus ||
                    oldIsPublic !== newIsPublic ||
                    this.hasChangesInArrayItemFields(log, 'inventoryDetails', 'isActive') ||
                    this.hasChangesInArrayItemFields(log, 'inventoryDetails', 'isRequired') ||
                    this.hasChangesInArrayItemFields(log, 'conditions', 'isActive') ||
                    this.hasChangesInArrayItemFields(log, 'conditions', 'isBillable');
            });
            this.changeLogs = filteredLogs;
        },

        hasChangesInField(log, fieldName) {
            let oldLog = log.old ? log.old : {};
            let newLog = log.new ? log.new : {};

            let oldValue = oldLog[fieldName] ? oldLog[fieldName] : "-";
            let newValue = newLog[fieldName] ? newLog[fieldName] : "-";
            return oldValue !== newValue;
        },

        hasChangesInArrayItems(log, fieldName) {
            const oldArrayField = log.old ? log.old[fieldName] : [];
            const newArrayField = log.new ? log.new[fieldName] : [];

            let addedItems = this.getAddedItems(oldArrayField, newArrayField);
            let removedItems = this.getRemovedItems(oldArrayField, newArrayField);
            return !_.isEmpty(addedItems) || !_.isEmpty(removedItems);
        },
        getAddedArrayItems(log, fieldName, arrayFieldName) {
            const oldArrayField = log.old ? log.old[fieldName] : {};
            const newArrayField = log.new ? log.new[fieldName] : {};

            let addedItems = this.getAddedItems(oldArrayField, newArrayField);
            return !_.isEmpty(addedItems) ? _.map(addedItems, arrayFieldName).join(", ") : "";
        },
        getRemovedArrayItems(log, fieldName, arrayFieldName) {
            const oldArrayField = log.old ? log.old[fieldName] : {};
            const newArrayField = log.new ? log.new[fieldName] : {};

            let removedItems = this.getRemovedItems(oldArrayField, newArrayField);
            return !_.isEmpty(removedItems) ? _.map(removedItems, arrayFieldName).join(", ") : "";
        },

        hasChangesInArrayItemFields(log, fieldName, arrayFieldName) {
            let oldArrayField = log.old ? log.old[fieldName] : {};
            let newArrayField = log.new ? log.new[fieldName] : {};
            let newValueIds = _.map(newArrayField, 'id');

            return _.some(newValueIds, id => {
                let oldValue = _.find(oldArrayField, o => {
                    return o.id === id;
                });
                oldValue = oldValue ? oldValue : {};

                let newValue = _.find(newArrayField, o => {
                    return o.id === id;
                });
                newValue = newValue ? newValue : {};

                return oldValue[arrayFieldName] !== newValue[arrayFieldName];
            });
        },
        getChangesInArrayItemFields(log, fieldName, arrayFieldName) {
            let oldArrayField = log.old ? log.old[fieldName] : {};
            let newArrayField = log.new ? log.new[fieldName] : {};
            let newValueIds = _.map(newArrayField, 'id');

            let withChanges = [];
            _.forEach(newValueIds, id => {
                let oldValue = _.find(oldArrayField, o => {
                    return o.id === id;
                });
                oldValue = oldValue ? oldValue : {};

                let newValue = _.find(newArrayField, o => {
                    return o.id === id;
                });
                newValue = newValue ? newValue : {};

                if(oldValue[arrayFieldName] !== newValue[arrayFieldName]) {
                    withChanges.push({
                        'id': id,
                        'old': this.getValue(oldValue[arrayFieldName]),
                        'new': this.getValue(newValue[arrayFieldName])
                    });
                }  
            });
            return withChanges;
        },

        // UTILS
        getFormattedDateWithTime(date) {
            return DateUtil.getFormattedDateWithTime(date);
        },
        getColorIndicator(oldObj, newObj) {
            const oldValue = oldObj && oldObj.unitCost ? oldObj.unitCost : 0;
            const newValue = newObj && newObj.unitCost ? newObj.unitCost : 0;

            if (oldValue === newValue)
                return '';
            else if (oldValue < newValue)
                return 'new-value'
            else return 'old-value';
        },
        getValue(toParseValue) {
            let value;
            if (typeof toParseValue === 'boolean') {
                value = toParseValue;
            } else {
                value = toParseValue ? toParseValue : "-";
            }
            return value
        },
        getOldValue(log, fieldName) {
            let oldLog = log.old ? log.old : {};

            let value = this.getValue(oldLog[fieldName]);
            return fieldName === 'rentRate' ? BillingReportUtil.formatMoneyValue('PHP', value) : value;
        },
        getNewValue(log, fieldName) {
            let newLog = log.new ? log.new : {};

            let value = this.getValue(newLog[fieldName]);
            return fieldName === 'rentRate' ? BillingReportUtil.formatMoneyValue('PHP', value) : value;
        },
        getAddedItems(oldItems, newItems) {
            const compare = (oldItems, newItems) => oldItems.id === newItems.id;
            return _.differenceWith(newItems, oldItems, compare);
        },
        getRemovedItems(oldItems, newItems) {
            const compare = (oldItems, newItems) => oldItems.id === newItems.id;
            return _.differenceWith(oldItems, newItems, compare);
        },
    }
}
</script>

<style scoped>
.new-value {
    color: green;
}

.old-value {
    color: red;
}

.changed-section {
    margin-bottom: 10px;
}
</style>