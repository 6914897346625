import { db } from '@/config/firebase';
import config from '@/config/env-constants';
import _ from 'lodash';


async function getAssetTypesByIds(assetTypeIds) {
    let dbRef = [];

    if (!_.isEmpty(assetTypeIds)) {
        let assetTypeIdsBy10 = _.chunk(assetTypeIds, config.maxInQuery);
        for (const groupOf10 of assetTypeIdsBy10) {
            let assetTypeRef = db.collection('assetTypes')
                .where("id", "in", groupOf10).get();
            dbRef.push(assetTypeRef);
        }
    }

    return Promise.all(dbRef).then(results => {
        let assetTypesObj = {};

        results.forEach(snapshot => {
            snapshot.forEach(doc => {
                if (doc.exists) {
                    let assetType = doc.data();
                    assetType.id = doc.id;
                    assetTypesObj[assetType.id] = assetType;
                }
            });
        });

        return Promise.all([assetTypesObj]);
    });
}

async function getAssetTypeById(assetTypeId) {
    const dbRef = db.collection('assetTypes').doc(assetTypeId);
    const querySnapshot = await dbRef.get();

    let assetType = {};
    assetType = {
        id: querySnapshot.id,
        ...querySnapshot.data()
    }

    return assetType
}

async function getAssetTypeByName(name) {
    let assetTypeObj = {};
    const query = db.collection('assetTypes').where("name", "==", name).limit(1);
    const querySnapshot = await query.get();

    querySnapshot.forEach((doc) => {
        assetTypeObj = doc.data();
    });

    return assetTypeObj;
}

export default {
    getAssetTypesByIds,
    getAssetTypeById,
    getAssetTypeByName
}