<template>
    <b-modal id="activate-asset-type" :title="modalTitle" ok-title="Save" ref="modal" @cancel="handleCancel" 
        @ok="handleOk" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
        :no-close-on-backdrop="true" hide-header-close>
        <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

        <div class="confirm-message">
            Are you sure you want to
            <b>{{ isActive === 'true' ? 'deactivate' : 'activate' }}</b> "{{ assetTypeName }}"?
        </div>
    </b-modal>
</template>

<script>
// Utils
import { AssetTypeUtil } from '@/utils/assetTypeUtil';
import { DateUtil } from '@/utils/dateutil';

// API
import assetTypeApi from '@/api/assetTypeApi';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


export default {
    name: 'activate-asset-type',
    components: {
        Loading
    },
    data() {
        return {
            selAssetType: {},
            isActive: true,
            
            loggedUser: this.$store.getters.loggedUser,
			// Check for loader
			isLoading: false,
        };
    },
    computed: {
        disableConfirmButtons() {
            return this.isLoading;
        },
        assetTypeName() {
            return this.selAssetType.name;
        },
        modalTitle() {
            return this.isActive === 'true' ? 'Deactivate Asset Type' : 'Activate Asset Type';
        },
    },
    mounted() {
        EventBus.$on('onUpdateSelAssetType', (selAssetType) => {
            this.selAssetType = selAssetType;
            this.isActive = selAssetType.isActive;
        });
    },
    methods: {
        toggleStatus(isActive) {
            let status = 'true';
            if (isActive === 'true') {
                status = 'false';
            }
            return status;
        },
        resetStatus() {
            this.selAssetType.isActive = this.isActive;
            this.$store.commit('SET_CURR_ASSET_TYPE', this.selAssetType);
        },
        handleCancel() {
            this.resetStatus();
        },

        getAssetTypeObj(param) {
            // timestamp
			param.dateUpdated = DateUtil.getCurrentTimestamp();
			param.updatedBy =  this.loggedUser.id;

            return AssetTypeUtil.cleanupFields(param);
        },
        async handleOk(evt) {
            try {
                // Prevent modal from closing
                evt.preventDefault();

                // show loading indicator
                this.isLoading = true;

                // update the status
                this.selAssetType.isActive = this.toggleStatus(this.isActive);

                let { data } = await assetTypeApi.saveAssetType({
                    currUserId: this.loggedUser.id,
                    assetType: this.getAssetTypeObj(this.selAssetType)
                });

                if (data.isSuccess) {
                    let successMsg = '';
                    if (data.assetType.isActive === 'true') {
                        successMsg = `Asset Type "${this.assetTypeName}" is now activated!`;
                    } else {
                        successMsg = `Asset Type "${this.assetTypeName}" is now deactivated!`;
                    }

                    this.$toaster.success(successMsg);
                    EventBus.$emit('onCloseSaveAssetType', { assetType: data.assetType });
                    this.$refs.modal.hide();
                } else {
                    let errorMsg = '';
                    if (this.toggleStatus(this.isActive) === 'true') {
                        errorMsg = `There's a problem encountered during activation of the asset type "${this.assetTypeName}"`;
                    } else {
                        errorMsg = `There's a problem encountered during deactivation of the asset type "${this.assetTypeName}"`;
                    }

                    this.resetStatus();
                    this.$toaster.error(errorMsg);
                }
            } catch (error) {
                let errorMsg = '';
                if (this.toggleStatus(this.isActive) === 'true') {
                    errorMsg = `There's a problem encountered during activation of the asset type "${this.assetTypeName}"`;
                } else {
                    errorMsg = `There's a problem encountered during deactivation of the asset type "${this.assetTypeName}"`;
                }

                this.resetStatus();
                this.$toaster.error(errorMsg);
            } finally {
                // hide loading indicator
                this.isLoading = false;
            }
        },
    },
    beforeDestroy() {
        EventBus.$off('onUpdateSelAssetType');
    },
}
</script>