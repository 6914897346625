import { render, staticRenderFns } from "./AddAssetType.vue?vue&type=template&id=17f9c676&scoped=true"
import script from "./AddAssetType.vue?vue&type=script&lang=js"
export * from "./AddAssetType.vue?vue&type=script&lang=js"
import style0 from "./AddAssetType.vue?vue&type=style&index=0&id=17f9c676&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17f9c676",
  null
  
)

export default component.exports