import axios from 'axios';
import config from '@/config/env-constants';


export default {
    baseUrl: 'https://us-central1-ayun-quantity-' + config.currEnv + '.cloudfunctions.net',

    saveAssetType(param) {
        let url = `${this.baseUrl}/saveAssetType`;
        return axios.post(url, {
            currUserId: param.currUserId,
            assetType: JSON.stringify(param.assetType)
        });
    },

    getAssetType(param) {
        let url = `${this.baseUrl}/getAssetType`;
        return axios.post(url, {
            currUserId: param.currUserId,
            assetTypeId: param.assetTypeId
        });
    },

    getAssetTypes(filterBy, view, currUserId) {
        let url = `${this.baseUrl}/getAssetTypes`;
        return axios.post(url, {
            view: view,
            currUserId: currUserId,
            filterBy: JSON.stringify(filterBy)
        });
    },
}