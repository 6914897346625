<template>
	<div>
		<b-card no-body>
			<b-tabs card>
				<b-tab title="Primary Information" active>
					<b-form-group label="Name" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ row.item.name }}
					</b-form-group>

					<b-form-group label="Owner" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ row.item.origin }}
					</b-form-group>

					<b-form-group label="Category" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<span style="width: 500px; word-wrap: break-word !important">{{
							showValue(row.item.type)
						}}</span>
					</b-form-group>

					<b-form-group label="Description" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						<span class="truncate-text">
							<truncate collapsed-text-class="collapsed" clamp="Show More" :length="100" less="Show Less"
								type="html" :text="row.item.description" />
						</span>
					</b-form-group>

					<b-form-group label="Date Created" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ getFormattedDateWithTime(row.item.dateCreated) }}
					</b-form-group>

					<b-form-group label="Date Updated" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right">
						{{ getFormattedDateWithTime(row.item.dateUpdated) }}
					</b-form-group>
				</b-tab>

				<b-tab title="Inventory Details">
					<b-col sm="12" class="mt-2">
						<AssetTypeInventoryDetailsView :row="row" :allUOMsObj="allUOMsObj" />
					</b-col>
				</b-tab>

				<b-tab title="Conditions">
					<b-col sm="12" class="mt-2">
						<AssetTypeConditionsDetailsView :row="row" />
					</b-col>
				</b-tab>

				<b-tab title="Rental" v-if="isBillingModuleEnabled">
					<b-form-group label="Is Rental?" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right" description="Determine if this asset type is for rental or not" v-show="isBillingModuleEnabled">
						<span v-if="row.item.isRental">
							<b-badge variant="success">YES</b-badge>
						</span>
						<span v-else>
							<b-badge variant="secondary">NO</b-badge>
						</span>
					</b-form-group>

					<b-form-group label="Rent Frequency" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right" v-if="isBillingModuleEnabled && row.item.isRental">
						{{ showValue(row.item.rentFrequency) }}
					</b-form-group>

					<b-form-group label="Rent Rate" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right" v-if="isBillingModuleEnabled && row.item.isRental">
						<span class="numFont">
							{{ formatMoneyValue(row.item.rentCurrency, row.item.rentRate) }}
						</span>
					</b-form-group>
				</b-tab>

				<b-tab title="Subscriptions">
					<b-form-group label="Status" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right" description="For usability status of the asset type">
						<span v-if="row.item.isActive === 'true'">
							<b-badge variant="success">Active</b-badge>
						</span>
						<span v-else>
							<b-badge variant="secondary">Inactive</b-badge>
						</span>
					</b-form-group>

					<b-form-group label="Is Public?" label-cols-sm="3" label-class="font-weight-bold pt-0"
						label-align-sm="right" description="For visibility of asset type to all companies" v-show="isSuperAdmin || isManager">
						<span v-if="row.item.isPublic">
							<b-badge variant="success">YES</b-badge>
						</span>
						<span v-else>
							<b-badge variant="secondary">NO</b-badge>
						</span>
					</b-form-group>
				</b-tab>

				<b-tab title="Change Logs">
					<AssetTypeChangeLogsDetailsView :row="row" :allCompaniesObj="allCompaniesObj" />
				</b-tab>
			</b-tabs>
		</b-card>
	</div>
</template>

<script>
// Components
import AssetTypeInventoryDetailsView from './AssetTypeInventoryDetailsView';
import AssetTypeConditionsDetailsView from './AssetTypeConditionsDetailsView';
import AssetTypeChangeLogsDetailsView from './AssetTypeChangeLogsDetailsView.vue';

// Utils
import { BillingReportUtil } from '@/utils/billingReportUtil';
import { DateUtil } from '@/utils/dateutil';

// Others
import truncate from 'vue-truncate-collapsed';
import _ from 'lodash';

export default {
	name: 'asset-type-details-view',
	components: {
		AssetTypeInventoryDetailsView,
		AssetTypeConditionsDetailsView,
		AssetTypeChangeLogsDetailsView,
		truncate
	},
	props: {
		row: {
			type: Object,
			required: true,
		},
		allCompaniesObj: {
			type: Object,
			required: true,
		},
		allUOMsObj: {
			type: Object,
			required: true,
		}
	},
	data() {
		return {
			isBillingModuleEnabled: false,
			isSuperAdmin: this.$store.getters.isSuperAdmin,
			isManager: this.$store.getters.isManager,
		}
	},
	computed: {
		companyObj() {
			let companyId = this.row.item.originId;
			return this.allCompaniesObj[companyId] ? this.allCompaniesObj[companyId] : {};
		}
	},
	mounted() {
		let permissions = this.companyObj.permissions ? this.companyObj.permissions : {};
		this.isBillingModuleEnabled = permissions.billing ? permissions.billing : false;
	},
	methods: {
		showValue(value) {
			if (!_.isEmpty(value)) {
				return value;
			}
			return '-';
		},
		getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
		formatMoneyValue(currency, amount) {
			return BillingReportUtil.formatMoneyValue(currency, amount);
		}
	},
};
</script>
