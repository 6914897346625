<template>
    <div>
        <!-- Select Actions and Items Per Page Options -->
        <b-row>
            <b-col sm="6" md="6">
                <span class="details-view-title">INVENTORY DETAILS</span>
                <div class="details-view-subtitle">List of inventory-related fields of this asset type</div>
            </b-col>
            <b-col sm="6" md="4" offset-md="2" class="mb-2 text-md-right">
                <b-input-group prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table show-empty striped hover :items="items" :fields="fields" :current-page="currentPage"
            :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" responsive>
            
            <template #cell(index)="data">
                {{ data.index + 1 }}
            </template>

            <template v-slot:cell(description)="row">
                <span class="truncate-text">
                    <truncate collapsed-text-class="collapsed" clamp="Show More" :length="50" less="Show Less"
                        type="html" :text="breakDescription(row.item.description, 25)" />
                </span>
            </template>
            
            <template v-slot:cell(isActive)="row">
                <span v-if="row.item.isActive === true">
                    <b-badge variant="success">Active</b-badge>
                </span>
                <span v-else>
                    <b-badge variant="secondary">Inactive</b-badge>
                </span>
            </template>
            
            <template v-slot:cell(isRequired)="row">
                <span v-if="row.item.isRequired === true">
                    <b-badge variant="success">YES</b-badge>
                </span>
                <span v-else>
                    <b-badge variant="danger">NO</b-badge>
                </span>
            </template>
            
        </b-table>

        <b-row>
            <b-col md="8" sm="12" class="my-1">
                <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
            </b-col>
            <b-col md="4" sm="12" class="my-1">
                <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                    class="my-0" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Utils
import { AssetTypeUtil } from '@/utils/assetTypeUtil';

// Others
import truncate from 'vue-truncate-collapsed';
import _ from 'lodash';

export default {
    name: 'asset-type-inventory-details-view',
    components: {
        truncate
    },
    props: {
        row: {
            type: Object,
            required: true,
        },
        allUOMsObj: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            items: [],
            fields: [
                {
                    key: 'index',
                    label: '#',
                },
                {
                    key: 'fieldName',
                    label: 'Name'
                },
                {
                    key: 'defaultValue',
                    label: 'Default Value'
                },
                {
                    key: 'isActive',
                    label: 'Status'
                },
                {
                    key: 'isRequired',
                    label: 'Is Required?'
                },
            ],

            currentPage: 1,
            perPage: 5,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
        }
    },
    mounted() {
        // retrieve inventoryDetails
        this.items = this.getInventoryDetails(this.row.item);
        this.totalRows = _.size(this.items);
    },
    methods: {
        getInventoryDetails(assetType) {
            let items = [];

            _.forEach(assetType.inventoryDetails, field => {
                let value = field.defaultValue;
                if (field.id === 'uom') {
                    value = AssetTypeUtil.getUOMDisplay(field.defaultValue, this.allUOMsObj);
                }

                items.push({
                    fieldName: field.fieldName,
                    defaultValue: value,
                    isRequired: field.isRequired,
                    isActive: field.isActive,
                });
            });

            return items;
        },
        breakDescription(description, length) {
            return description.length > length ? description.replace(new RegExp(`(?![^\\n]{1,${length}}$)([^\\n]{1,${length}})\\s`, 'g'), '$1<br>') : description;
        }
    }
}

</script>