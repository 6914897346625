<template>
    <div>
        <b-row class="my-3">
            <b-col sm="8">
                <b>INVENTORY DETAILS</b>
            </b-col>
        </b-row>

        <b-row class="mt-3" no-gutters>
            <b-col sm="10" md="5">
                <b-form-group label="Field Name" label-for="Field Name" description="NOTE: up to of 25 characters only">
                    <b-form-input name="Field Name" type="text" v-model="fieldName" 
                        v-validate="{ regex: fieldNameRegex }" maxlength="25" />
                    <span v-show="errors.has('Field Name')" class="help-block">{{
                        errors.first('Field Name')
                    }}</span>
                </b-form-group>
            </b-col>
            <b-col sm="1">
                <b-button class="add-field" variant="success" @click="onAddField">Add</b-button>
            </b-col>
            <b-col sm="6" md="5" offset-sm="6" offset-md="1" class="text-md-right mb-2">
                <b-input-group align="right" class="pagination" prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table show-empty striped hover :items="form.inventoryDetails" :fields="fields" :current-page="currentPage"
            :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" responsive>

            <template v-slot:cell(defaultValue)="row">
                <v-select v-if="row.item.id === 'uom'" name="Unit of Measure" class="style-chooser" label="text"
                    :options="uomOptions" :reduce="(uom) => uom.value" v-model="row.item.defaultValue"
                    v-validate="{ required: true }">

                    <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                            No results found for
                            <em>
                                <strong>{{ search }}</strong>
                            </em>
                        </template>
                        <em :style="{ opacity: 0.5 }" v-else>
                            Start typing to search for a uom
                        </em>
                    </template>
                </v-select>
                <span v-show="errors.has('Unit of Measure')" class="help-block">
                    {{ errors.first('Unit of Measure') }}
                </span>
                <b-form-input v-if="row.item.id !== 'uom'" name="Default Value" type="text" v-model="row.item.defaultValue" maxlength="25" />
            </template>

            <template v-slot:cell(isActive)="row">
                <b-form-checkbox name="active" v-model="row.item.isActive" switch>
                    {{ row.item.isActive === true ? 'YES' : 'NO' }}
                </b-form-checkbox>
            </template>
            
            <template v-slot:cell(isRequired)="row">
                <b-form-checkbox name="billable" v-model="row.item.isRequired" switch>
                    {{ row.item.isRequired === true ? 'YES' : 'NO' }}
                </b-form-checkbox>
            </template>

            <template v-slot:cell(action)="row">
                <b-button v-if="!isDefaultField(row.item.id)" size="sm" v-b-tooltip.hover.top="'Remove'"
                    variant="danger" @click="onRemoveField(row.item.id)" class="mr-1">
                    <i class="fa fa-trash-o"></i>
                </b-button>
                <span v-else><i>Default</i></span>
            </template>
        </b-table>

        <b-row>
            <b-col md="8" sm="12" class="my-1">
                <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
            </b-col>
            <b-col md="4" sm="12" class="my-1">
                <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                    class="my-0" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Util
import { AssetTypeUtil } from '@/utils/assetTypeUtil';
import { ValidationUtil } from '@/utils/validationUtil';

// Others
import _ from 'lodash';

export default {
    name: 'asset-type-inventory-details',
    props: {
        form: {
            type: Object,
            required: true,
        },
        uomOptions: {
			type: Array,
			required: true
		}
    },
    data() {
        return {
            // Asset Type Inventory Details
            fieldName: '',
            fields: [
                {
                    key: 'fieldName',
                    label: 'Name',
                    sortable: true,
                },
                {
                    key: 'defaultValue',
                    label: 'Default Value',
                    sortable: true,
                },
                {
                    key: 'isActive',
                    label: 'Active',
                    sortable: true,
                },
                {
                    key: 'isRequired',
                    label: 'Required',
                    sortable: true,
                },
                'action',
            ],

            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
        }
    },
    computed: {
        fieldNameRegex() {
            return /^[A-Za-z0-9 ]{1,25}$/;
        },
        totalRows() {
            return this.form.inventoryDetails.length
        }
    },
    methods: {
        isDefaultField(id) {
            const defaultFields = _.map(AssetTypeUtil.getDefaultInventoryDetails(), 'id');
            return defaultFields.includes(id);
        },

        async onAddField() {
            if (!this.validateField()) {
                return;
            }

            this.form.inventoryDetails.push({
                id: this.fieldName.toLowerCase(),
                fieldName: this.fieldName,
                defaultValue: '',
                isRequired: false,
                isActive: true,
            });

            this.fieldName = '';
        },
        validateField() {
            let isValid = true;

            if (this.fieldName.length <= 0) {
                this.$toaster.warning('Field is required.');
                isValid = false;
            } else if (this.hasExistingField()) {
                this.$toaster.warning('Field already exists on this asset type.');
                isValid = false;
            } else if (!this.fieldNameRegex.test(this.fieldName)) {
                this.$toaster.warning('Invalid field name format. Must contain lower and upper case A-Z characters only.');
                isValid = false;
            }

            return isValid;
        },
        hasExistingField() {
            return ValidationUtil.objectHasField('id', this.fieldName, this.form.inventoryDetails);
        },

        onRemoveField(fieldNameId) {
            let index = _.findIndex(this.form.inventoryDetails, (item) => {
                return item.id == fieldNameId;
            });

            if (index >= 0) {
                this.form.inventoryDetails = _.filter(this.form.inventoryDetails, (item) => {
                    return item.id !== fieldNameId;
                });
            }
        },
    }
}

</script>

<style scoped>
.add-field {
    margin-top: 32px;
    margin-left: 10px;
}

.pagination {
    margin-top: 29px;
}
</style>