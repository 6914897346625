<template>
    <div>
        <b-row class="my-3">
            <b-col sm="8">
                <b>CONDITIONS</b>
            </b-col>
        </b-row>

        <b-row class="mt-3" no-gutters>
            <b-col sm="10" md="5">
                <b-form-group label="Condition" label-for="Condition" description="NOTE: up to of 15 characters only">
                    <b-form-input name="Condition" type="text" v-model="condition" v-validate="{
                        regex: conditionRegex,
                    }" maxlength="15" />
                    <span v-show="errors.has('Condition')" class="help-block">{{
                        errors.first('Condition')
                    }}</span>
                </b-form-group>
            </b-col>
            <b-col sm="1">
                <b-button class="add-condition" variant="success" @click="onAddCondition">Add</b-button>
            </b-col>
            <b-col sm="6" md="5" offset-sm="6" offset-md="1" class="text-md-right mb-2">
                <b-input-group align="right" class="pagination" prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table show-empty striped hover :items="form.conditions" :fields="fields" :current-page="currentPage"
            :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" responsive>
            <template v-slot:cell(description)="row">
                <b-form-input name="description" type="text" v-model="row.item.description" maxlength="25" />
            </template>
            <template v-slot:cell(isBillable)="row">
                <b-form-checkbox name="billable" v-model="row.item.isBillable" switch>
                    {{ row.item.isBillable === true ? 'YES' : 'NO' }}
                </b-form-checkbox>
            </template>
            <template v-slot:cell(isActive)="row">
                <b-form-checkbox name="active" v-model="row.item.isActive" switch>
                    {{ row.item.isActive === true ? 'YES' : 'NO' }}
                </b-form-checkbox>
            </template>
            <template v-slot:cell(action)="row">
                <b-button v-if="!isDefaultCondition(row.item.id)" size="sm" v-b-tooltip.hover.top="'Remove'"
                    variant="danger" @click="onRemoveCondition(row.item.id)" class="mr-1">
                    <i class="fa fa-trash-o"></i>
                </b-button>
                <span v-else><i>Default</i></span>
            </template>
        </b-table>

        <b-row>
            <b-col md="8" sm="12" class="my-1">
                <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
            </b-col>
            <b-col md="4" sm="12" class="my-1">
                <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                    class="my-0" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Util
import { AssetTypeUtil } from '@/utils/assetTypeUtil';
import { ValidationUtil } from '@/utils/validationUtil';

// Others
import _ from 'lodash';

export default {
    name: 'asset-type-conditions',
    props: {
        form: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            // Asset Type Conditions
            condition: '',
            fields: [
                {
                    key: 'condition',
                    label: 'Condition',
                    sortable: true,
                },
                {
                    key: 'description',
                    label: 'Description',
                    sortable: true,
                },
                {
                    key: 'isActive',
                    label: 'Active',
                    sortable: true,
                },
                {
                    key: 'isBillable',
                    label: 'Billable',
                    sortable: true,
                },
                'action',
            ],

            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
        }
    },
    watch: {
        condition: function (newVal) {
            if (newVal.length > 0) {
                this.condition = ValidationUtil.removeExcessWhiteSpace(newVal);
            }
        }
    },
    computed: {
        conditionRegex() {
            return /^[A-Za-z0-9 ]{1,15}$/;
        },
        totalRows() {
            return this.form.conditions.length
        }
    },
    methods: {
        isDefaultCondition(condition) {
            const defaultConditions = _.map(AssetTypeUtil.getDefaultConditions(), 'id');
            return defaultConditions.includes(condition);
        },

        async onAddCondition() {
            if (!this.validateCondition()) {
                return;
            }

            this.form.conditions.push({
                id: this.condition.toLowerCase(),
                condition: this.condition,
                description: 'Default description',
                isBillable: false,
                isActive: false,
            });

            this.condition = '';
        },
        validateCondition() {
            let isValid = true;

            if (this.condition.length <= 0) {
                this.$toaster.warning('Condition is required.');
                isValid = false;
            } else if (this.hasExistingCondition()) {
                this.$toaster.warning('Condition already exists on this asset type.');
                isValid = false;
            } else if (!this.conditionRegex.test(this.condition)) {
                this.$toaster.warning('Invalid condition format. Must contain lower and upper case A-Z characters only.');
                isValid = false;
            }

            return isValid;
        },
        hasExistingCondition() {
            return ValidationUtil.objectHasField('id', this.condition, this.form.conditions);
        },

        onRemoveCondition(conditionId) {
            let index = _.findIndex(this.form.conditions, (item) => {
                return item.id == conditionId;
            });

            if (index >= 0) {
                this.form.conditions = _.filter(this.form.conditions, (item) => {
                    return item.id !== conditionId;
                });
            }
        },
    }

}
</script>

<style scoped>
.add-condition {
    margin-top: 32px;
    margin-left: 10px;
}

.pagination {
    margin-top: 29px;
}
</style>