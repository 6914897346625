<template>
    <div>
        <!-- Select Actions and Items Per Page Options -->
        <b-row>
            <b-col sm="6" md="6">
                <span class="details-view-title">ASSET TYPE CONDITIONS</span>
                <div class="details-view-subtitle">List of conditions to classify the asset status</div>
            </b-col>
            <b-col sm="6" md="4" offset-md="2" class="mb-2 text-md-right">
                <b-input-group prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table show-empty striped hover :items="conditions" :fields="fields" :current-page="currentPage"
            :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" responsive>
            <template v-slot:cell(description)="row">
                <span class="truncate-text">
                    <truncate collapsed-text-class="collapsed" clamp="Show More" :length="50" less="Show Less"
                        type="html" :text="breakDescription(row.item.description, 25)" />
                </span>
            </template>
            <template v-slot:cell(isBillable)="row">
                <span v-if="row.item.isBillable === true">
                    <b-badge variant="success">YES</b-badge>
                </span>
                <span v-else>
                    <b-badge variant="danger">NO</b-badge>
                </span>
            </template>
            <template v-slot:cell(isActive)="row">
                <span v-if="row.item.isActive === true">
                    <b-badge variant="success">Active</b-badge>
                </span>
                <span v-else>
                    <b-badge variant="secondary">Inactive</b-badge>
                </span>
            </template>
        </b-table>

        <b-row>
            <b-col md="8" sm="12" class="my-1">
                <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
            </b-col>
            <b-col md="4" sm="12" class="my-1">
                <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                    class="my-0" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Others
import _ from 'lodash';
import truncate from 'vue-truncate-collapsed';

export default {
    name: 'asset-type-conditions-details-view',
    components: {
        truncate
    },
    props: {
        row: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            conditions: [],
            fields: [
                {
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'condition',
                    label: 'Condition'
                },
                {
                    key: 'description',
                    label: 'Description'
                },
                {
                    key: 'isActive',
                    label: 'Status'
                },
                {
                    key: 'isBillable',
                    label: 'Is Billable?'
                }
            ],

            currentPage: 1,
            perPage: 5,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,
        }
    },
    mounted() {
        // retrieve conditions
        this.conditions = this.getConditions(this.row.item);
        this.totalRows = _.size(this.conditions);
    },
    methods: {
        getConditions(assetType) {
            let conditions = [];

            _.forEach(assetType.conditions, (item, key) => {
                let index = key + 1;
                conditions.push({
                    index: index,
                    condition: item.condition,
                    description: item.description,
                    isBillable: item.isBillable,
                    isActive: item.isActive
                });
            });

            return conditions;
        },
        breakDescription(description, length) {
            return description.length > length ? description.replace(new RegExp(`(?![^\\n]{1,${length}}$)([^\\n]{1,${length}})\\s`, 'g'), '$1<br>') : description;
        }
    }
}

</script>